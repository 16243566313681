import { EventSourcePlus } from 'event-source-plus'
import { seconds } from 'milliseconds'

import config from '~/src/App/config'
import { getTokens } from '~/src/Lib/Auth'
import createLogger from '~/src/Lib/Logging'
import { getClientId } from '~/src/Lib/Utils'

const logger = createLogger('IO/SSE')

const maxRetryCount = 10
const maxRetryInterval = 64e3

const ERROR_CODES = {
  401: 'NOT_AUTHENTICATED',
  402: 'INVALID_FACILITY',
  403: 'NO_PERMISSION',
}

const urlProvider = facilityId => `${config.API_URL}/sse/${facilityId}/`

let eventSource = null
let lastAttempt = 0
let lastFacility = null
let lastListen = null
/**
 * Connect to a SSE server and attach message and error handlers
 * @param {(data: any) => void} messageHandler
 * @param {(error: any) => void} errorHandler
 */
export default async (currentFacilityId, messageHandler, errorHandler) => new Promise(resolve => {
  logger.debug('attempting SSE connect')
  if (
    eventSource
    && eventSource.readyState !== EventSourcePlus.CLOSED
    && typeof eventSource.close === 'function'
  ) {
    logger.debug('SSE already connected; closing it')
    eventSource.close()
  }
  if (Date.now() - lastAttempt < seconds(5)) {
    logger.warn('SSE connection attempt too soon after last attempt')
    resolve(null)
    return
  }

  lastAttempt = Date.now()
  if (lastFacility !== currentFacilityId) {
    if (lastListen && 'abort' in lastListen) {
      logger.debug('cleaning up connection after facility change from', lastFacility, 'to', currentFacilityId)
      lastListen.abort()
    }
    lastFacility = currentFacilityId
  }
  const { access } = getTokens()

  try {
    eventSource = new EventSourcePlus(urlProvider(currentFacilityId), {
      maxRetryCount,
      maxRetryInterval,
      headers: {
        Authorization: `Bearer ${access}`,
        'X-App': config.APP,
        'X-Facility': currentFacilityId || '',
        'X-Client': getClientId(),
        'X-Client-Version': localStorage.debug && localStorage.clientVersion
          ? localStorage.clientVersion
          : config.VERSION,
      },
    })
    lastListen = eventSource.listen({
      onMessage(message) {
        if (message.event === 'message') {
          if (!message.data || !message.data.startsWith('{')) {
            logger.debug('invalid event.data')
            return
          }
          try {
            const data = JSON.parse(message.data)
            messageHandler(data)
          } catch (err) {
            logger.debug(err)
          }
          return
        }
        if (message.event === 'error') {
          logger.debug('SSE error message:', message)
          errorHandler(message)
          return
        }
        if (message.event === 'stream-open') return
        if (message.event === 'keep-alive') return
        logger.debug('unknown message:', {
          lastAttempt,
          lastFacility,
          message
        })
      },
      onRequest: logger.debug.bind(null, '[onRequest]'),
      onRequestError(data) {
        logger.debug('SSE error on request:', data)
        errorHandler(data)
      },
      onResponse({ request, options, response }) {
        const data = { request, options, response }
        logger.debug('[onResponse]', data)
      },
      onResponseError(error) {
        const { status } = error.response
        if (status in ERROR_CODES) {
          logger.debug('closed due to invalid state', error)
          errorHandler(error)
        } else {
          logger.debug('closed for unknown reason:', error)
        }
      }
    })
    resolve(eventSource)
  } catch (err) {
    logger.warn('error thrown:', err)
  }
})
