import { SvgIcon } from '@mui/material'

export const path1 = `M47.32 12.32c-8.45 0-22.76 1.16-22.76 5.49 0 .22.05.44.12.64.06.29-.2 5.8.67 7.29.14.24.29.32.29.32 6.22 2.62 13.96 3.83 20.53 3.83 9.63 0 17.92-2.05 21.66-3.15h-.02l-1.4 24.59c-.04.63-.45 1.2-1.04 1.42-14.1 5.37-28.5 1.87-33.93.16a2.576 2.576 0 0 1-1.8-2.28l-1.72-23.68-2.29-.89s1.66 26.46 2.23 27.41c.08.13.58.51.58.51.54.22 9.17 3.69 20.45 3.69 5.82 0 12.34-.93 18.81-3.7 0 0 .52-.16.62-.66.51-2.58 1.67-27.36 1.67-27.36.07-.06.14-.12.19-.19.28-.39.43-.85.43-1.33V17.8c-.01-4.32-14.83-5.48-23.29-5.48Zm0 2c13.11 0 20.47 2.27 20.76 3.49-.29 1.22-7.65 3.49-20.76 3.49-13.11 0-20.5-2.28-20.76-3.48.26-1.22 7.63-3.5 20.76-3.5ZM27.53 24.67a.962.962 0 0 1-.61-.82l-.24-3.49h-.01c4.29 2.25 14.19 2.93 20.66 2.93 6.47 0 16.95-.7 21.28-3.03v3.33c0 .49-.32.93-.8 1.07-5.32 1.57-24.12 6.3-40.28.01Z
`
export const path2 = `
M48.17 12.32c-8.46 0-22.77 1.16-22.77 5.49 0 .22.05.44.12.64.06.29-.2 5.8.67 7.29.14.24.29.32.29.32 6.22 2.62 13.96 3.83 20.53 3.83 9.63 0 17.92-2.05 21.66-3.15h-.02l-1.4 24.59c-.04.63-.45 1.2-1.04 1.42-14.1 5.37-28.5 1.87-33.93.16a2.576 2.576 0 0 1-1.8-2.28l-1.72-23.68-2.29-.89s1.68 26.45 2.23 27.41c.23.4.58.51.58.51.54.22 9.17 3.69 20.45 3.69 5.82 0 12.34-.93 18.81-3.7 0 0 .52-.16.62-.66.51-2.58 1.67-27.36 1.67-27.36.07-.06.14-.12.19-.19.28-.39.43-.85.43-1.33V17.8c0-4.33-14.82-5.49-23.28-5.49v.01Zm0 2c13.11 0 20.47 2.27 20.76 3.49-.29 1.22-7.65 3.49-20.76 3.49-13.11 0-20.51-2.28-20.77-3.48.26-1.22 7.63-3.5 20.76-3.5h.01Zm-19.8 10.35a.962.962 0 0 1-.61-.82l-.24-3.49h-.01c4.29 2.25 14.19 2.93 20.66 2.93 6.47 0 16.95-.7 21.28-3.03v3.33c0 .49-.32.93-.8 1.07-5.32 1.57-24.12 6.3-40.28.01Z
`
const Pot = props => (
  <SvgIcon viewBox="0 0 96 70" {...props}>
    <g clipPath="url(#a)" fill="currentColor">
      <path d={path1} />
      <path d={path2} />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M24.56 12.32h46.88v45.36H24.56z" />
      </clipPath>
    </defs>
  </SvgIcon>
)

export default Pot
