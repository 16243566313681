import { identity } from 'ramda'

import { singularize } from 'inflection'

import { queryClient } from '~/src/IO/Api'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '~/src/Lib/Utils'
import { prepareScope } from '~/src/Store/utils'

import createLogger from '../Logging'
import { actionFactory, asyncActionFactory } from './actions'
import {
  configBasedReducerFactory,
  defaultAsyncHandlersFactory,
  dirtyCleaner,
  dirtyUpdater,
} from './reducers'
import { entitySelectorsFactory } from './selectors'

const logger = createLogger('createEntityBundle')

export const defaultFetchPrepareData = payload => {
  if (typeof payload === 'number' || typeof payload === 'string') return { id: payload }
  return {
    id: payload?.id,
    params: payload?.params,
  }
}

export const defaultApiActionsFactory = (name, readOnly, apiUrl = name) => [
  {
    name,
    action: 'fetch',
    prepareData: defaultFetchPrepareData,
    handler: ({ apiFetch, payload }) => apiFetch(`/${apiUrl}/${payload.id}/`, payload.params)
      .then(res => {
        const id = String(payload.id ?? res.id ?? '')
        if (id) queryClient.setQueryData([apiUrl, id], res)
        return res
      }),
  },
].concat(
  readOnly
    ? EMPTY_ARRAY
    : [
      {
        name,
        action: 'save',
        handler: ({ apiFetch, payload }) => apiFetch(
          payload.id ? `/${apiUrl}/${payload.id}/` : `/${apiUrl}/`,
          payload,
          { method: payload.id ? 'PUT' : 'POST' }
        ).then(res => {
          queryClient.invalidateQueries({ queryKey: [apiUrl] })
          return res
        }),
      },
      {
        name,
        action: 'update',
        handler: ({ apiFetch, payload }) => apiFetch(
          `/${apiUrl}/${payload.id}/`,
          payload,
          { method: 'PATCH' }
        ).then(res => {
          const id = String(payload.id ?? res.id ?? '')
          if (id) queryClient.setQueryData([apiUrl, id], res)
          else queryClient.invalidateQueries({ queryKey: [apiUrl] })
          return res
        }),
      },
      {
        name,
        action: 'delete',
        prepareData: identity,
        handler: ({ apiFetch, payload: { id } }) => apiFetch(
          `/${apiUrl}/${id}/`,
          null,
          { method: 'DELETE' }
        ).then(res => {
          queryClient.invalidateQueries({ queryKey: [apiUrl] })
          return res
        }),
        remove: true,
      },
    ]
)

export const addCacheAction = (name, reducerConfig, bundle) => {
  const cacheAction = actionFactory('cache', name)
  Object.assign(bundle, { [cacheAction.actionName]: cacheAction })
  Object.assign(reducerConfig, { [cacheAction.type]: dirtyUpdater })
}
export const addClearDirtyAction = (name, reducerConfig, bundle) => {
  const clearAction = actionFactory('clear_dirty', name)
  Object.assign(bundle, { [clearAction.actionName]: clearAction })
  Object.assign(reducerConfig, { [clearAction.type]: dirtyCleaner })
}
export const addSetCurrentAction = (name, reducerConfig, bundle) => {
  const setCurrentAction = actionFactory('set_current', name)
  Object.assign(bundle, { [setCurrentAction.actionName]: setCurrentAction })
  Object.assign(reducerConfig, {
    [setCurrentAction.type]: (state, action) => {
      // logger.info(`${setCurrentAction.actionName} reducer:`, { state, action })
      if (action.type !== setCurrentAction.type) return state
      // logger.info('updating current to', action.payload)
      const { current: previous } = state
      const { payload } = action
      let next = payload
      if (typeof payload === 'function') {
        next = payload(previous)
      }
      return {
        ...state,
        current: next,
      }
    },
  })
}
export const addAsyncAction = (config, reducerConfig, bundle) => {
  const actionCreator = asyncActionFactory(config)

  Object.assign(bundle, { [actionCreator.actionName]: actionCreator })
  Object.assign(
    reducerConfig,
    defaultAsyncHandlersFactory(actionCreator.types, config)
  )
}
export const addCustomActions = (
  customActions,
  bundleConfig,
  reducerConfig,
  bundle
) => {
  const { apiConfig = EMPTY_OBJECT, name, parents } = bundleConfig
  customActions.forEach(({ async = false, ...config }) => {
    const { snackbar: defaultSnackbar } = apiConfig
    const { action, prepareData = identity, snackbar: customSnackbar } = config
    const snackbar = customSnackbar ?? defaultSnackbar
    if (async) {
      addAsyncAction(
        {
          ...apiConfig,
          ...config,
          name: config.name ?? name,
          parents,
          prepareData,
          snackbar: action.indexOf('fetch') === 0 && typeof snackbar !== 'function'
            ? false
            : snackbar,
        },
        reducerConfig,
        bundle
      )
      return
    }
    const { actionName, type, creator, reducer } = config
    Object.assign(bundle, { [actionName]: creator })
    Object.assign(reducerConfig, { [type]: reducer })
  })
}

// name should be the plural name
/**
 * Create a new entity bundle for a given data entity. Simplifies the process of creating
 * CRUD _and_ custom actions, reducers and selectors for a redux-bundler ecosystem.
 * @param {object} bundleConfig
 * @param {string} bundleConfig.name The name of this entity schema (typically plural: rooms, harvests, etc.)
 * @param {Object} bundleConfig.apiConfig Configuration for API actions
 * @param {string} bundleConfig.apiConfig.url The base URL for API requests
 * @param {Object} bundleConfig.apiConfig.schema The base URL for API requests
 * @param {boolean} [bundleConfig.autoFetch=true] Should this entity auto-fetch based detected relevance?
 * @param {Object[]} bundleConfig.customActions Custom actions to add to this bundle
 * @param {function(Object, Object): Object} [bundleConfig.customReducer=identity] Custom reducer to use for this entity
 * @param {Object} [bundleConfig.defaultState] Initial state for this entity
 * @param {string} [bundleConfig.idAttribute=id] What property of this entity identifies it (id, key)?
 * @param {string[]} [bundleConfig.persistActions] List of action types that should persist in the cache
 * @param {Object<string, string>} [bundleConfig.parents] Map of parent schema names to parent id attributes
 * @param {boolean} [bundleConfig.readOnly = false] Is this a read-only entity (no API writes)?
 * @param {import('~/src/Store/utils').Scope} [bundleConfig.scope] Should this entity be scoped based on something else in redux state?
 * @param {string} [bundleConfig.singularName] The singular name of this entity (defaults to singularizing the name)
 * Given selector result and entity, returns whether entity is allowed in the current scope
 */
export default bundleConfig => {
  const {
    name,
    apiConfig: {
      url: apiUrl,
      ...apiConfig
    },
    autoFetch = true,
    customActions,
    customReducer = identity,
    defaultState,
    idAttribute = 'id',
    parents,
    persistActions = EMPTY_ARRAY,
    readOnly = false,
    scope,
    singularName = singularize(name),
    ...bundleProps
  } = bundleConfig

  const reducerConfig = {}
  const bundle = { name, persistActions }
  if (scope) {
    const preparedScope = prepareScope(scope)
    bundle.scope = preparedScope
  }
  if (!readOnly) {
    addCacheAction(singularName, reducerConfig, bundle)
    addClearDirtyAction(singularName, reducerConfig, bundle)
  }
  addSetCurrentAction(singularName, reducerConfig, bundle)
  const {
    snackbar: defaultSnackbar,
    ...apiConfigRest
  } = apiConfig

  defaultApiActionsFactory(name, readOnly, apiUrl).forEach(({ action, ...config }) => {
    const { [action]: customConfig = EMPTY_OBJECT } = apiConfig
    const { snackbar: customSnackbar } = customConfig
    const snackbar = customSnackbar ?? defaultSnackbar
    addAsyncAction(
      {
        ...apiConfigRest,
        ...config,
        ...customConfig,
        ...(customConfig?.wrapper ? { handler: customConfig.wrapper(config.handler) } : undefined),
        action,
        parents,
        singularName,
        snackbar:
          action.indexOf('fetch') === 0 && typeof snackbar !== 'function'
            ? false
            : snackbar,
      },
      reducerConfig,
      bundle
    )
  })

  if (Array.isArray(customActions)) {
    addCustomActions(customActions, { ...bundleConfig, singularName }, reducerConfig, bundle)
  }
  bundle.reducer = configBasedReducerFactory(
    reducerConfig,
    defaultState,
    customReducer,
    readOnly
  )
  const selectors = entitySelectorsFactory({
    name,
    autoFetch,
    idAttribute,
    readOnly,
    scope: bundle.scope,
    singularName,
  })

  Object.assign(
    bundle,
    selectors,
    bundleProps
  )

  bundle.$config = bundleConfig

  return bundle
}
