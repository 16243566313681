import { path } from 'ramda'

import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

const VARIANT_COLORS = {
  dark: path(['background', 'dark']),
  error: path(['error', 'main']),
  light: path(['background', 'secondary']),
  main: path(['background', 'default']),
  paper: path(['background', 'paper']),
}

/**
 * A tooltip that is easily styled with Aroya background colors
 * @component
 * @alias Tooltip
 * @composes
 * from: '@mui/material/Tooltip'
 * link: https://material-ui.com/api/tooltip/#props
 * @example
 * <Box
 *   display="grid"
 *   gap="1rem"
 *   gridTemplateColumns="repeat(auto-fill, minmax(7rem, 1fr))"
 *   padding="4rem"
 *   bgcolor="rgba(255, 255, 255, 0.32)"
 * >
 *   {[
 *     [null, undefined],
 *     ['dark', 'top'],
 *     ['light', 'right'],
 *     ['main', 'bottom'],
 *     ['paper', 'left']
 *   ].map(([variant, placement], i) => (
 *     <Tooltip
 *       arrow
 *       placement={placement}
 *       title={(
 *         <Box component={T.Tiny} color="text.primary" padding="0.5rem">
 *           {String(variant)}
 *         </Box>
 *       )}
 *       variant={variant}
 *     >
 *       <T>tooltip: {String(variant)}</T>
 *     </Tooltip>
 *   ))}
 * </Box>
 */
const TooltipComponent = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
), { shouldForwardProp: prop => prop !== 'variant' })(({ theme, variant }) => {
  if (!variant || !(variant in VARIANT_COLORS)) return {}
  const { [variant]: colorGetter } = VARIANT_COLORS
  const color = colorGetter(theme.palette)
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: color,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color,
    },
  }
})

export default TooltipComponent
