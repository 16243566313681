import { SvgIcon } from '@mui/material'

export const path1 = `
m35.68 34.23-10.41-6.442a.522.522 0 0 0-.53-.01l-10.66 5.986a.5.5 0 0 0-.21.223l-.05.527v.051l.04-.517 8.21 5.001c.16.112.14.152.16.203l-.07.507-8.35-5.163.17 12.965c0 .162.1.335.25.426l10.36 6.35c.17.102.38.102.54 0l10.41-6.39a.508.508 0 0 0 .25-.447l.12-12.793a.513.513 0 0 0-.25-.456l.02-.02Zm-1.49 12.316c0 .112-.06.224-.16.274l-8.44 5.042c-.21.132-.48-.03-.48-.284V41.22a.32.32 0 0 1 .15-.274l8.49-5.214c.21-.132.49.03.49.284l-.05 10.53Zm23.86-25.272-9.83-6.087a.49.49 0 0 0-.5-.01l-10.06 5.65a.455.455 0 0 0-.2.214l-.05.497v.05l.04-.497 8.15 4.961s.07.061.09.102v.466l-8.29-5.042.16 12.235a.5.5 0 0 0 .24.426l9.78 5.996c.16.101.36.101.51 0l9.83-6.036a.5.5 0 0 0 .24-.426l.11-12.073a.516.516 0 0 0-.24-.436l.02.01ZM56.64 32.9c0 .112-.06.203-.15.264l-7.97 4.758c-.2.122-.46-.03-.46-.264v-9.78c0-.111.06-.203.15-.263l8.02-4.93c.2-.123.46.02.46.263l-.05 9.942v.01ZM78.58 9.456l-8.63-5.337a.406.406 0 0 0-.44 0L60.68 9.08a.418.418 0 0 0-.18.193l-.04.436v.04l.04-.435 7.15 4.352s.06.05.08.091v.507L60.46 9.75l.14 10.733c0 .152.08.294.21.375l8.58 5.256c.14.08.31.08.45 0l8.63-5.296a.444.444 0 0 0 .21-.375l.1-10.592a.461.461 0 0 0-.21-.385l.01-.01ZM77.34 19.65c0 .092-.05.183-.13.234l-6.99 4.18a.265.265 0 0 1-.4-.234v-8.583c0-.09.05-.182.13-.233l7.03-4.322c.18-.111.4.02.4.234l-.04 8.724ZM36.52 35.152l.88-.67 9.77 6.138-10.74 6.584.09-12.052Zm22.41-13.674 1.08-.619 9.04 5.813-10.12 6.706v-11.9Z
`
export const path2 = `
m88.49 14.284-8.3-4.92-.84.557V20.24l8.11-5.286-.11 5.976c0 .558-.08.67-.17.79a.63.63 0 0 0-.09.122L17.01 64.826l.17-5.945-.69.416 7.82-4.647-10.65-6.574-7.67 4.22 10.47 6.99-5.94-3.966-.34.568-4.19-2.8v5.732l10.2 6.908c.05.061.12.122.19.163.12.07.26.111.4.111s.28-.04.41-.111l70.54-43.248.13-.081.06-.142s.03-.05.06-.081c.21-.274.39-.589.38-1.41l.12-6.585h-.08l.09-.06Z
`
const BlockOnSlab = props => (
  <SvgIcon viewBox="0 0 96 70" {...props}>
    <path d={path1} />
    <path d={path2} />
  </SvgIcon>
)

export default BlockOnSlab
