import { useCallback, useMemo, useSyncExternalStore } from 'react'

import { useReduxBundlerStore } from 'redux-bundler-hook'

import { shallowEquals } from '~/src/Lib/Utils/equality'

const ACTION_PATTERN = /^do[A-Z]\w+$/
const SELECTOR_PATTERN = /^select[A-Z]\w+$/
export const useConnectModern = (...keys) => {
  const store = useReduxBundlerStore()
  const [actions, selectors] = useMemo(() => keys.reduce(([
    actionCreators,
    selectorNames
  ], key) => {
    let matched = false
    if (ACTION_PATTERN.test(key)) {
      matched = true
      actionCreators[key] = (...params) => store.action(key, params)
    }
    if (SELECTOR_PATTERN.test(key)) {
      matched = true
      selectorNames.push(key)
    }
    if (matched) {
      return [actionCreators, selectorNames]
    }
    throw new ReferenceError(`"${key}" is neither a valid action creator nor a valid selector identifier.`)
  }, [{}, []]), [keys, store])
  const subscribeToSelectors = useCallback(cb => store.subscribeToSelectors(selectors, cb), [selectors, store])
  const getSelectionSnapshot = useMemo(() => {
    let cached = store.select(selectors)
    return () => {
      const current = store.select(selectors)
      if (shallowEquals(cached, current, 4)) {
        return cached
      }
      cached = current
      return cached
    }
  }, [selectors, store])

  const selected = useSyncExternalStore(subscribeToSelectors, getSelectionSnapshot)

  return useMemo(() => ({
    ...selected,
    ...actions,
  }), [actions, selected])
}
