import { createElement } from 'react'

import i18n from 'i18n-literally'

import Bag from '../UI/Icons/Bag'
import Block from '../UI/Icons/Block'
import BlockOnSlab from '../UI/Icons/BlockOnSlab'
import ContainerOther from '../UI/Icons/ContainerOther'
import Pot from '../UI/Icons/Pot'

export const API_PATH = '/irrigationSubstrateSettings/'

export const SUBSTRATE_TYPES = Object.freeze({
  GENERIC: 'GENERIC',
  ROCKWOOL: 'ROCKWOOL',
  COCO: 'COCO',
  PEAT: 'PEAT',
  SOIL: 'SOIL',
  SOILLESS: 'SOILLESS',
})
export const SUBSTRATE_TYPE_LABELS = Object.freeze({
  GENERIC: i18n`Generic Soilless`,
  ROCKWOOL: i18n`Rockwool`,
  COCO: i18n`Coco Coir`,
  PEAT: i18n`Peat Moss`,
  SOIL: i18n`Soil`,
  SOILLESS: i18n`Soilless`,
})
export const SUBSTRATE_TYPE_ORDER = Object.freeze(['ROCKWOOL', 'COCO', 'PEAT', 'SOIL', 'GENERIC'])
export const SUBSTRATE_TYPE_OPTIONS = Object.freeze(SUBSTRATE_TYPE_ORDER.map(value => ({
  label: SUBSTRATE_TYPE_LABELS[value],
  value,
})))

export const CONTAINER_TYPES = Object.freeze({
  POT: 'POT',
  BAG: 'BAG',
  BLOCK: 'BLOCK',
  BLOCK_ON_SLAB: 'BLOCK_ON_SLAB',
  OTHER: 'OTHER',
})
export const CONTAINER_TYPE_ICONS = {
  BAG: Bag,
  BLOCK: Block,
  BLOCK_ON_SLAB: BlockOnSlab,
  POT: Pot,
  OTHER: ContainerOther,
}
export const CONTAINER_TYPE_LABELS = Object.freeze({
  BAG: i18n`Bag`,
  BLOCK: i18n`Block`,
  BLOCK_ON_SLAB: i18n`Block on Slab`,
  OTHER: i18n`Other`,
  POT: i18n`Pot`,
})
export const CONTAINER_TYPE_ORDER = Object.freeze(['BLOCK', 'BLOCK_ON_SLAB', 'POT', 'BAG', 'OTHER'])
export const CONTAINER_TYPE_OPTIONS_BY_SUBSTRATE = Object.freeze({
  [SUBSTRATE_TYPES.GENERIC]: CONTAINER_TYPE_ORDER.slice(2).map(value => ({
    Icon: () => createElement(CONTAINER_TYPE_ICONS[value], { sx: { fontSize: 72 } }),
    label: CONTAINER_TYPE_LABELS[value],
    value
  })),
  get [SUBSTRATE_TYPES.COCO]() { return this[SUBSTRATE_TYPES.GENERIC] },
  get [SUBSTRATE_TYPES.PEAT]() { return this[SUBSTRATE_TYPES.GENERIC] },
  get [SUBSTRATE_TYPES.SOIL]() { return this[SUBSTRATE_TYPES.GENERIC] },
  [SUBSTRATE_TYPES.ROCKWOOL]: (['BLOCK', 'BLOCK_ON_SLAB', 'OTHER']).map(value => ({
    Icon: () => createElement(CONTAINER_TYPE_ICONS[value], { sx: { fontSize: 72 } }),
    label: CONTAINER_TYPE_LABELS[value],
    value
  })),
})

// BEGIN DEPRECATED
export const SUBSTRATE_VALUES = {
  SOILLESS: 'SOILLESS',
  ROCKWOOL: 'ROCKWOOL',
  COCOCOIR: 'COCOCOIR',
  PEATMOIS: 'PEATMOIS',
  SOIL: 'SOIL'
}

export const SUBSTRATE_OPTIONS = [
  { value: 'GENERIC', label: i18n`Generic soilless` },
  { value: 'ROCKWOOL', label: i18n`Rockwool` },
  { value: 'COCO', label: i18n`Coco coir` },
  { value: 'PEAT', label: i18n`Peat moss` },
  { value: 'SOIL', label: i18n`Soil` },
]
export const SUBSTRATE_SETTINGS = 'substrateSettings'
