import {
  cloneElement,
  isValidElement,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { Box } from '@mui/material'

import createLogger from '~/src/Lib/Logging'
import { EMPTY_OBJECT } from '~/src/Lib/Utils'

import Tooltip from './Tooltip'

const logger = createLogger('OverflowTooltip')

/**
 * Automatically add a tooltip to elements that are overflowing
 * @component
 * @example
 * import { faker as f } from '@faker-js/faker'
 * <Box display="grid" gap="1rem" gridTemplateColumns="repeat(auto-fill, minmax(10rem, 1fr))">
 *   <OverflowTooltip><T noWrap>{f.lorem.paragraph()}</T></OverflowTooltip>
 *   <OverflowTooltip variant="dark"><T noWrap>{f.lorem.paragraph()}</T></OverflowTooltip>
 *   <OverflowTooltip variant="light"><T noWrap>{f.lorem.paragraph()}</T></OverflowTooltip>
 *   <OverflowTooltip variant="main" title={f.lorem.sentence()}>
 *     <T.Body noWrap>{f.lorem.paragraph()}</T.Body>
 *   </OverflowTooltip>
 * </Box>
 */
const OverflowTooltip = ({ children, interactive = true, title = null, ...rest }) => {
  const [overflowed, setOverflowed] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    if (!ref.current) return
    const selfOverflowed = ref.current.scrollWidth > ref.current.clientWidth
    const childrenOverflowed = ref.current.children
      ? Array.from(ref.current.children).some(el => el.scrollWidth > el.clientWidth)
      : false
    setOverflowed(Boolean(selfOverflowed || childrenOverflowed))
  }, [])

  const childIsElement = isValidElement(children)

  const tooltip = useMemo(() => {
    if (title) return title
    if (!childIsElement) return ''
    return cloneElement(children, children.props.noWrap ? { noWrap: false } : EMPTY_OBJECT)
  }, [childIsElement, children, title])

  return (
    <Tooltip
      disableInteractive={!interactive}
      disableHoverListener={!overflowed}
      enterDelay={400}
      title={tooltip}
      variant="light"
      {...rest}
    >
      <Box
        ref={ref}
        clone={childIsElement}
        component="span"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        overflow="hidden"
      >
        {children}
      </Box>
    </Tooltip>
  )
}

const Memoized = memo(OverflowTooltip)
Memoized.propTypes = Tooltip.propTypes

export default Memoized
