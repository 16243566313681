import { useMemo, useRef } from 'react'

import i18n from 'i18n-literally'

import { styled } from '@mui/material/styles'

import { EMPTY_ARRAY, EMPTY_OBJECT } from '~/src/Lib/Utils'
import Tooltip from '~/src/UI/Shared/Tooltip'

import { useLock } from './utils'

const Outer = styled('div')(({ theme }) => ({
  opacity: theme.palette.states.inactive,
}))

const Inner = styled('div')({ display: 'contents' })

/**
 * Component to lock a feature based on a test with support for passing redux-bundler selectors.
 * @param {Object} props
 * @param {import('react').ReactNode} props.children
 * @param {?string[]} props.selectors
 * @param {function(?string[], Object<string, any>): boolean} props.test
 * @param {import('react').ReactNode} [props.tooltip]
 * @returns {import('react').ReactNode}
 */
const Lock = ({
  children,
  selectors = EMPTY_ARRAY,
  test,
  tooltip = i18n`This feature is locked.`,
  tooltipProps = EMPTY_OBJECT,
  ...props
}) => {
  const outerRef = useRef(null)
  const popperRef = useRef(null)
  const PopperProps = useMemo(() => ({
    popperRef,
    anchorEl: {
      getBoundingClientRect: () => {
        const { top, left, height, width } = outerRef.current.getBoundingClientRect()
        const x = left + (width / 2)
        const y = top + (height / 2)

        return new DOMRect(x, y, 0, 0)
      }
    }
  }), [])
  return useLock(test, selectors, props)
    ? (
      <Tooltip
        placement="top"
        PopperProps={PopperProps}
        variant="light"
        {...tooltipProps}
        title={tooltip}
      >
        <Outer ref={outerRef}>
          <Inner inert="">{children}</Inner>
        </Outer>
      </Tooltip>
    )
    : children
}

export default Lock
