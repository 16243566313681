import { createContext } from 'react'

import i18n from 'i18n-literally'

export const MODBUS_CONTROLLER_MODELS = ['argus', 'rhythm']
Object.freeze(MODBUS_CONTROLLER_MODELS)

// these constants are used to figure out the context in which we operate with irrigation schedules
// possible contexts are: room-level, harvest-level, phase-level or a schedule template
export const ROOM_IRRIGATION_SCHEDULES = 'ROOM_LEVEL_IRRIGATION_SCHEDULES'
export const HARVEST_IRRIGATION_SCHEDULES = 'HARVEST_LEVEL_IRRIGATION_SCHEDULES'
export const PHASE_IRRIGATION_SCHEDULES = 'PHASE_LEVEL_IRRIGATION_SCHEDULES'
export const IRRIGATION_SCHEDULE_TEMPLATE = 'IRRIGATION_SCHEDULE_TEMPLATE'

export const IRRIGATION_SCHEDULE_STATUSES = Object.freeze({
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PAUSED: 'PAUSED',
  PAUSING: 'PAUSING',
  UNCONFIRMED: 'UNCONFIRMED',
  PENDING: 'PENDING',
  REMOVING: 'REMOVING',
  ERROR: 'ERROR',
})

export const IRRIGATION_SCHEDULE_PENDING_STATUSES = Object.freeze({
  PAUSING: 'PAUSING',
  PENDING: 'PENDING',
  REMOVING: 'REMOVING',
})

export const IRRIGATION_CONTROLLER_PENDING_STATUSES = Object.freeze({
  CONFIGURING: 'CONFIGURING',
  NEW: 'NEW',
  PENDING: 'PENDING',
  RETRYING: 'RETRYING',
})

export const IRRIGATION_CONTROLLER_ERROR_STATUSES = Object.freeze({
  DUPLICATE_DEVICE: 'DUPLICATE DEVICE',
  ERROR: 'ERROR',
  INVALID_DEVICE: 'INVALID DEVICE',
  INVALID_FIRMWARE: 'INVALID FIRMWARE',
  INVALID_PASSWORD: 'INVALID PASSWORD',
  NO_HOSTNAME: 'NO HOSTNAME',
  UNABLE_TO_CONNECT: 'UNABLE TO CONNECT',
})

export const IRRIGATION_CONTROLLER_STATUSES = Object.freeze({
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  ...IRRIGATION_CONTROLLER_PENDING_STATUSES,
  ...IRRIGATION_CONTROLLER_ERROR_STATUSES,
})

export const HUMANIZED_IRRIGATION_CONTROLLER_STATUSES = Object.freeze({
  ACTIVE: i18n`Active`,
  INACTIVE: i18n`Inactive`,
  CONFIGURING: i18n`Configuring`,
  NEW: i18n`New`,
  PENDING: i18n`Pending`,
  RETRYING: i18n`Retrying`,
  DUPLICATE_DEVICE: i18n`Duplicate Device`,
  ERROR: i18n`Error`,
  INVALID_DEVICE: i18n`Invalid Device`,
  INVALID_FIRMWARE: i18n`Invalid Firmware`,
  INVALID_PASSWORD: i18n`Invalid Password`,
  NO_HOSTNAME: i18n`No Hostname`,
  UNABLE_TO_CONNECT: i18n`Unable to Connect`
})

export const IRRIGATION_CONTROLLER_PORT_STATUSES = Object.freeze({
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  INVALID: 'INVALID',
})

// helper array to calculate programs duration in seconds; also, select component options in duration and interval inputs
export const TIME_UNIT_OPTIONS = Object.freeze([
  {
    value: 1,
    label: i18n`Sec`,
    toSeconds: v => v,
    name: i18n`Second`,
    symbol: i18n`s`,
  },
  {
    value: 2,
    label: i18n`Min`,
    toSeconds: v => v * 60,
    name: i18n`Minute`,
    symbol: i18n`m`,
  },
  {
    value: 3,
    label: i18n`Hrs`,
    toSeconds: v => v * 60 * 60,
    name: i18n`Hour`,
    symbol: i18n`h`,
  },
])
export const REPEAT_AFTER_UNIT_OPTIONS = Object.freeze(TIME_UNIT_OPTIONS.slice(1))

export const TIME_DURATION = 'TIME_DURATION'
export const PERCENTAGE_DURATION = 'PERCENTAGE_DURATION'
export const VOLUME_DURATION = 'VOLUME_DURATION'
export const TIME_DURATION_TYPE_VALUE = 1
// select component options above the duration input in the program card
export const durationTypes = Object.freeze([
  {
    type: TIME_DURATION,
    category: 'Time',
    value: TIME_DURATION_TYPE_VALUE,
    label: i18n`For how long`,
    measurementUnit: '',
    inputOptions: TIME_UNIT_OPTIONS,
  },
  // NOTE: commenting %/ml types until we fully suport them
  // {
  //   type: PERCENTAGE_DURATION,
  //   category: 'Water Content',
  //   value: 2,
  //   label: i18n`How much (in %)`,
  //   measurementUnit: '%',
  //   name: 'Water Content',
  // },
  // {
  //   type: VOLUME_DURATION,
  //   category: 'Volume',
  //   value: 3,
  //   label: i18n`How much (in ml)`,
  //   measurementUnit: 'ml',
  //   name: 'Volume',
  // },
])

// helper functions to get correct select value on init form state of schedule editing
export const getDurationTypeSelectValue = (unitId, durationUnits) => {
  const durationUnitObj = durationUnits.find(unitObj => unitObj.id === unitId) || {}
  const durationType = durationTypes.find(dType => dType.category === durationUnitObj.category?.name) || {}
  return durationType.value || 1 // 1 is default type (time)
}
export const getDurationInputSelectValue = (unitId, durationUnits) => {
  const durationUnitObj = durationUnits.find(unitObj => unitObj.id === unitId) || {}
  const inputSelectOption = TIME_UNIT_OPTIONS.find(timeOption => timeOption.symbol === durationUnitObj.symbol) || {}
  if (!inputSelectOption.value) {
    const [defaultOption] = TIME_UNIT_OPTIONS
    return defaultOption.value
  }
  return inputSelectOption.value
}
export const getRepeatAfterSelectValue = (repeatAfterUnit, durationUnits) => {
  const durationUnitObj = durationUnits.find(unitObj => unitObj.id === repeatAfterUnit) || {}
  const inputSelectOption = REPEAT_AFTER_UNIT_OPTIONS.find(timeOption => timeOption.symbol === durationUnitObj.symbol) || {}
  if (!inputSelectOption.value) {
    const [defaultOption] = REPEAT_AFTER_UNIT_OPTIONS
    return defaultOption.value
  }
  return inputSelectOption.value
}

export const SUBSTRATE_CONFIGS = {
  SINGLE: 'SINGLE',
  STACKED: 'STACKED',
  MULTIPLE: 'MULTIPLE',
}

export const FIRMWARE_ALERT_MESSAGE = Object.freeze({
  ABOUT_TO_LOSE_SUPPORT: i18n`Irrigation Firmware about to lose support.`,
  MAY_NOT_WORK_AS_EXPECTED: i18n`Some functions may not work as expected.`,
  NOT_SUPPORTED: i18n`Irrigation Firmware not supported.`,
  NOT_YET_SUPPORTED: i18n`Irrigation Firmware not yet supported.`,
  UPDATE_TO_LATEST_SUPPORTED: i18n`Please update to the latest supported Firmware.`,
})

export const NO_SEQUENTIAL_GROUP = -1

export const SHARED_PORT_MESSAGE = i18n`All zones configured to the same controller port are automatically selected.`

export const IRRIGATION_JOURNAL_EVENT_TYPES = Object.freeze({
  SEQ_GROUP_CHANGE: i18n`Port Sequential Group Change(s)`,
  PORT_ZONE_CHANGE: i18n`Port Zone Association Change(s)`,
  EMERGENCY_SHOT: i18n`Emergency Shot`,
  RUN_ONCE: i18n`Manual Irrigation`,
  SCHEDULE_CHANGE: i18n`Irrigation Changed`,
  SCHEDULE_PAUSED: i18n`Irrigation Paused`,
  SCHEDULE_RESUMED: i18n`Irrigation Resumed`,
  SCHEDULE_DELETED: i18n`Irrigation Schedule Deleted`,
  IRRIGATION_STARTED: i18n`Irrigation Started`,
  IRRIGATION_COMPLETED: i18n`Irrigation Completed`,
})

export const ASI_DEFAULT_GUARDRAILS = Object.freeze({
  gen: {
    lightsBuffer: [2, 2],
    maxEcpw: 9,
    flushDuration: 5,
    interval: [10, 60],
    minVwc: 25,
    duration: [0.5, 6],
    drainGoalPct: 5,
  },
  veg: {
    lightsBuffer: [1, 2],
    maxEcpw: 6,
    flushDuration: 5,
    interval: [15, 60],
    minVwc: 25,
    duration: [0.5, 5],
    drainGoalPct: 5,
  },
  mode: 'veg',
  value: 10
})

const ASIStartMsg = i18n`Start irrigating at approx.`
const ASIMaintainMsg = i18n`Maintain near field capacity until`
const ASIFieldCapacityMsg = i18n`to field capacity`
const defaultASIEndTime = i18n`end of irrigation window`
export const ASI_META = Object.freeze({
  1: {
    mode: 'GEN',
    label: i18n`Maximum Generative`,
    description: (
      startTime,
      endTime = defaultASIEndTime
    ) => [
      [ASIStartMsg, startTime, ASIFieldCapacityMsg].join(' '),
      [ASIMaintainMsg, endTime].join(' ')
    ].join('\n'),
  },
  2: {
    mode: 'GEN',
    label: i18n`Very High Generative`,
    description: (
      startTime,
      endTime = defaultASIEndTime
    ) => [
      [ASIStartMsg, startTime, ASIFieldCapacityMsg].join(' '),
      [ASIMaintainMsg, endTime].join(' ')
    ].join('\n'),
  },
  3: {
    mode: 'GEN',
    label: i18n`High Generative`,
    description: (
      startTime,
      endTime = defaultASIEndTime
    ) => [
      [ASIStartMsg, startTime, ASIFieldCapacityMsg].join(' '),
      [ASIMaintainMsg, endTime].join(' ')
    ].join('\n'),
  },
  4: {
    mode: 'GEN',
    label: i18n`Medium Generative`,
    description: (
      startTime,
      endTime = defaultASIEndTime
    ) => [
      [ASIStartMsg, startTime, ASIFieldCapacityMsg].join(' '),
      [ASIMaintainMsg, endTime].join(' ')
    ].join('\n'),
  },
  5: {
    mode: 'GEN',
    label: i18n`Mild Generative`,
    description: (
      startTime,
      endTime = defaultASIEndTime
    ) => [
      [ASIStartMsg, startTime, ASIFieldCapacityMsg].join(' '),
      [ASIMaintainMsg, endTime].join(' ')
    ].join('\n'),
  },
  6: {
    mode: 'VEG',
    label: i18n`Mild Vegetative`,
    description: (
      startTime,
      endTime = defaultASIEndTime
    ) => [
      [ASIStartMsg, startTime, ASIFieldCapacityMsg].join(' '),
      [ASIMaintainMsg, endTime].join(' ')
    ].join('\n'),
  },
  7: {
    mode: 'VEG',
    label: i18n`Medium Vegetative`,
    description: (
      startTime,
      endTime = defaultASIEndTime
    ) => [
      [ASIStartMsg, startTime, ASIFieldCapacityMsg].join(' '),
      [ASIMaintainMsg, endTime].join(' ')
    ].join('\n'),
  },
  8: {
    mode: 'VEG',
    label: i18n`High Vegetative`,
    description: (
      startTime,
      endTime = defaultASIEndTime
    ) => [
      [ASIStartMsg, startTime, ASIFieldCapacityMsg].join(' '),
      [ASIMaintainMsg, endTime].join(' ')
    ].join('\n'),
  },
  9: {
    mode: 'VEG',
    label: i18n`Very High Vegetative`,
    description: (
      startTime,
      endTime = defaultASIEndTime
    ) => [
      [ASIStartMsg, startTime, ASIFieldCapacityMsg].join(' '),
      [ASIMaintainMsg, endTime].join(' ')
    ].join('\n'),
  },
  10: {
    mode: 'VEG',
    label: i18n`Maximum Vegetative`,
    description: (
      startTime,
      endTime = i18n`end of the full irrigation window`
    ) => [
      [ASIStartMsg, startTime, ASIFieldCapacityMsg].join(' '),
      [ASIMaintainMsg, endTime].join(' ')
    ].join('\n'),
  },
})

export const DrawerContext = createContext({})
