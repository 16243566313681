import { styled } from '@mui/material/styles'

import Block from './Block'
import QuestionMark from './QuestionMark'

const Root = styled('div')(({ theme }) => ({
  position: 'relative',
  '& svg': {
  },
  '& svg:first-of-type': {
    opacity: theme.palette.states.active,
    fontSize: 'inherit',
  },
  '& svg:last-of-type': {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, calc(-50% - 3px))',
    fontSize: '0.25em',
  }
}))

const ContainerOther = props => (
  <Root {...props} className="center-all">
    <Block />
    <QuestionMark />
  </Root>
)

export default ContainerOther
